import { create } from 'zustand';

interface IUserAccountsFilter {
  country: string | null;
  changeCountry: (country: string) => void;
}

export const useUserAccountsFilter = create<IUserAccountsFilter>(set => {
  return {
    country: null,
    changeCountry(country: string) {
      set({ country });
    },
  };
});
