import ARFlag from '@/assets/flags/AR.png';
import BRFlag from '@/assets/flags/BR.png';
import COFlag from '@/assets/flags/CO.png';
import ECFlag from '@/assets/flags/EC.png';
import ESFlag from '@/assets/flags/ES.png';
import ITFlag from '@/assets/flags/IT.png';
import USFlag from '@/assets/flags/US.png';

export const matchCountryToFlag: Record<string, string> = {
  AR: ARFlag,
  BR: BRFlag,
  ES: ESFlag,
  US: USFlag,
  CO: COFlag,
  IT: ITFlag,
  EC: ECFlag,
};
