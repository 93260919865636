import { ArrowUpRight, Globe } from 'lucide-react';

import HeaderImg from '@/assets/images/card-app-selection-header.png';
import { IUserInfoAccount } from '@/common/types/User';
import { matchCountryToFlag } from '@/common/utils/matchCountryToFlag';

import * as style from './styles';

type AccountSelectionCardProps = {
  account: IUserInfoAccount;
  onClick: () => void;
  isSelected?: boolean;
};

export function AccountSelectionCard({
  account,
  onClick,
  isSelected,
}: AccountSelectionCardProps) {
  return (
    <button
      type="button"
      onClick={onClick}
      className={style.container({ isSelected })}
      disabled={isSelected}
    >
      <img src={HeaderImg} alt={account.title} className={style.logo()} />

      <div className={style.info()}>
        <div className={style.titleContainer()}>
          {matchCountryToFlag[account?.country] ? (
            <img
              src={matchCountryToFlag[account?.country]}
              alt={account.country}
              className={style.flag()}
            />
          ) : (
            <Globe size={16} />
          )}
          <p className={style.title()}>{account.title}</p>
        </div>
        {!isSelected && (
          <div className={style.iconContainer()}>
            <ArrowUpRight size={24} className={style.icon()} />
          </div>
        )}
      </div>
    </button>
  );
}
