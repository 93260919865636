import { Filter, Globe } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import { useUserAccountsFilter } from '@/apps/login/hooks/useUserAccountsFilter';
import Button from '@/common/components/Button';
import { IUserInfoUserAccount } from '@/common/types/User';
import { generateId } from '@/common/utils';
import { matchCountryToFlag } from '@/common/utils/matchCountryToFlag';

import * as style from './styles';

type FilterDropDownProps = {
  userAccounts: IUserInfoUserAccount[];
};

export function FiltersDropDown({ userAccounts }: FilterDropDownProps) {
  const { t } = useTranslation(['countries', 'common']);
  const { changeCountry, country } = useUserAccountsFilter();

  const nationalityCompanyOptions =
    userAccounts.reduce((acc, curr) => {
      if (
        !acc.some(option => option.Account.country === curr.Account.country)
      ) {
        acc.push(curr);
      }

      return acc;
    }, [] as IUserInfoUserAccount[]) || [];

  const id = generateId();

  if (nationalityCompanyOptions.length <= 1 && !country) return null;

  return (
    <div className={style.container()}>
      <Button
        id={id}
        variant="secondary"
        leadingIcon={<Filter size={16} className="text-brand-pure" />}
        text={t('common:filters')}
        size="small"
        className="hs-dropdown-toggle w-fit"
      />

      <div className={style.menuContent()} aria-labelledby={id}>
        {nationalityCompanyOptions.map(option => (
          <div key={option.accountId} className={style.menuItem()}>
            <button
              type="button"
              className={style.buttonItem()}
              onClick={() => changeCountry(option.Account.country)}
            >
              {matchCountryToFlag[option?.Account?.country] ? (
                <img
                  src={matchCountryToFlag[option?.Account?.country]}
                  alt={option?.Account?.country}
                  className="h-[18px] w-7"
                />
              ) : (
                <Globe size={18} />
              )}

              <span className={style.label()}>
                {t(option?.Account?.country)}
              </span>
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}
