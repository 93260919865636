import { ArrowUpRight } from 'lucide-react';

import HeaderImg from '@/assets/images/card-app-selection-header2.png';
import { maskCompanyDocument } from '@/common/utils/maskCompanyDocument';

import * as style from './styles';

type CardAppProps = {
  title?: string;
  description?: string;
  currentCompanyId?: string;
  companyId?: string;
  country?: string;
  onClick: () => void;
};

export function CardApp({
  title,
  description,
  currentCompanyId,
  companyId,
  country,
  onClick,
}: CardAppProps) {
  return (
    <button
      type="button"
      onClick={onClick}
      className={style.container({
        selected:
          companyId !== undefined &&
          currentCompanyId !== undefined &&
          companyId === currentCompanyId,
      })}
    >
      <img src={HeaderImg} alt={title} className={style.logo()} />

      <div className={style.info()}>
        <p className={style.title()}>{title}</p>
        <p className={style.description()}>
          {maskCompanyDocument(description || '', country || '')}
        </p>

        <div className={style.iconContainer()}>
          <ArrowUpRight size={24} className={style.icon()} />
        </div>
      </div>
    </button>
  );
}
