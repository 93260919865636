/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import flagsmith from 'flagsmith';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';

import { useUserStore } from '@/common/hooks/UserStore';
import { removeItemLocal } from '@/common/utils/localStorage';

import { ENV } from './env';
import i18n from './i18n';

export const httpClient = axios.create({
  baseURL: ENV.VITE_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

const urlsToIgnoreUndefinedData = ['/user/get-user-info', '/oauth/token'];

httpClient.interceptors.request.use(
  config => {
    const modifiedConfig = {
      ...config,
      headers: { ...config.headers, locale: i18n.language || 'pt-BR' },
    };
    return modifiedConfig;
  },
  error => {
    return Promise.reject(error);
  },
);

httpClient.interceptors.request.use(
  config => {
    const { accountId } = useUserStore.getState();
    const shouldIgnore = urlsToIgnoreUndefinedData.some(url =>
      config.url?.includes(url),
    );

    if (accountId && !shouldIgnore) {
      const modifiedConfig = {
        ...config,
        headers: { ...config.headers, account: accountId },
      };
      return modifiedConfig;
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

httpClient.interceptors.response.use(
  response => ({
    ...response,
  }),
  async error => {
    if (error.response.status === 401) {
      toast.error(i18n.t('common:sessionExpired'), {
        containerId: 'secondary',
        autoClose: 3000,
        toastId: 'session-expired',
      });

      setTimeout(() => {
        removeItemLocal('app');
        window.location.href = '/';
        flagsmith.logout();
        httpClient.defaults.headers.Authorization = '';
        Cookies.remove('accessToken');
        Cookies.remove('refreshToken');
        Cookies.remove('sessionState');
      }, 3500);
    }

    throw error;
  },
);
