import { cva } from 'class-variance-authority';

export const container = cva(['hs-dropdown', 'relative', 'inline-flex']);

export const menuContent = cva([
  'hs-dropdown-menu',
  'transition-[opacity,margin]',
  'duration',
  'hs-dropdown-open:opacity-100',
  'opacity-0',
  'w-auto',
  'hidden',
  'mt-2',
  'min-w-fit',
  'z-50',
  'min-w-[8rem]',
  'overflow-hidden',
  'rounded-md',
  'border',
  'border-neutral-200',
  'bg-white',
  'p-1',
  'text-neutral-950',
  'shadow-md',
]);

export const menuItem = cva([
  'items-center',
  'rounded-sm',
  'px-2',
  'py-1.5',
  'text-sm',
  'outline-none',
  'transition-colors',
  'focus:bg-neutral-100',
  'focus:text-neutral-900',
]);

export const buttonItem = cva(['flex', 'items-center', 'gap-2', 'w-full']);

export const label = cva(['text-sm', 'font-normal']);
