import axios from 'axios';
import { toast } from 'react-toastify';

import { removeItemLocal } from '@/common/utils/localStorage';

import { ENV } from './env';
import i18n from './i18n';

export const telemetryHttpClient = axios.create({
  baseURL: ENV.VITE_TELEMETRY_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

telemetryHttpClient.interceptors.request.use(
  config => {
    const modifiedConfig = {
      ...config,
      headers: { ...config.headers, locale: i18n.language || 'pt-BR' },
    };
    return modifiedConfig;
  },
  error => {
    return Promise.reject(error);
  },
);

telemetryHttpClient.interceptors.response.use(
  response => ({
    ...response,
  }),
  async error => {
    if (error.response.status === 401) {
      toast.error('Sua sessão expirou, faça login novamente', {
        containerId: 'secondary',
        autoClose: 3000,
        toastId: 'session-expired',
      });

      setTimeout(() => {
        removeItemLocal('app');
        window.location.href = '/';
      }, 3500);
    }

    throw error;
  },
);
