import flagsmith from 'flagsmith';
import Cookies from 'js-cookie';
import { LayoutGrid } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import { AccountSelectionCard } from '@/common/components/AccountSelectionCard';
import { Modal } from '@/common/components/Modal';
import { useUserStore } from '@/common/hooks/UserStore';
import { useToast } from '@/common/hooks/useToast';
import { IUserInfoUserAccount } from '@/common/types/User';
import { setItemLocal } from '@/common/utils/localStorage';

import * as style from './styles';

export function AccountSelectionModal() {
  const { user, accountId: currentAccountId, saveUserAccount } = useUserStore();

  const { t } = useTranslation(['login/accountSelection', 'common']);
  const { toast } = useToast();

  const handleLoadApp = async (selectedAccount: IUserInfoUserAccount) => {
    if (!user) {
      toast.error(t('login/signIn:loadNexproModuleError'));
      return;
    }

    const role = selectedAccount?.role;
    const accountIdSelected = selectedAccount?.accountId;

    if (accountIdSelected) {
      setItemLocal('accountId', accountIdSelected);
      saveUserAccount({
        accountId: accountIdSelected,
        role,
      });
      Cookies.set('accountId', accountIdSelected);
      Cookies.set('role', role);
      await flagsmith.setTrait('role', role);
      window.HSOverlay.close(
        document.getElementById('hs-account-selection-modal')!,
      );
      toast.success(t('common:success'), t('accountChanged'));
    }
  };

  return (
    <Modal
      id="account-selection-modal"
      title={t('changeAccountAccess')}
      titleIcon={<LayoutGrid size={24} className={style.icon()} />}
      dispensable
      subTitle={
        <p className={style.subTitle()}>
          {t('selectOtherAccount')}{' '}
          <strong className={style.nexpro()}>Nexpro</strong>.
        </p>
      }
    >
      <div className={style.content()}>
        <section className={style.section()}>
          <ul className={style.list()}>
            {user?.UserAccount?.map(acc => (
              <li key={acc.accountId}>
                <AccountSelectionCard
                  account={acc.Account}
                  onClick={() => handleLoadApp(acc)}
                  isSelected={acc.accountId === currentAccountId}
                />
              </li>
            ))}
          </ul>
        </section>
      </div>
    </Modal>
  );
}
