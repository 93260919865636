import { cva } from 'class-variance-authority';

export const container = cva(
  [
    'w-60',
    'rounded-lg',
    'border',
    'border-neutral-200',
    'shadow-sm',
    'hover:border-brand-pure',
    'hover:shadow-md',
    'cursor-pointer',
    'relative',
    'w-full',
    'max-w-sm',
    'disabled:cursor-not-allowed',
    'disabled:hover:shadow-sm',
  ],
  {
    variants: {
      isSelected: {
        true: [
          'border-2',
          'border-brand-pure/80',
          'shadow-md',
          'bg-brand-pure/10',
        ],
        false: ['border-neutral-200', 'shadow-sm'],
      },
    },
  },
);

export const logo = cva(['w-full', 'object-contain', 'rounded-t-lg']);

export const info = cva([
  'py-2',
  'px-4',
  'flex',
  'items-center',
  'justify-between',
]);

export const flag = cva(['w-7', 'h-[18px]']);

export const titleContainer = cva(['flex', 'items-center', 'gap-2']);

export const title = cva([
  'text-neutral-800',
  'text-base',
  'font-semibold',
  'overflow-hidden overflow-ellipsis whitespace-pre',
]);

export const description = cva(['text-neutral-700', 'text-xs', 'font-light']);

export const iconContainer = cva([
  'top-2',
  'right-4',
  'bg-brand-pure',
  'rounded-lg',
  'p-1',
  'w-fit',
]);

export const icon = cva(['text-white']);
