import { CheckCircle2, XCircle, AlertTriangle, X } from 'lucide-react';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

import Info from '@/assets/icons/info.svg?react';
import { generateId } from '@/common/utils';

import * as style from './styles';

type AlertProps = {
  message?: string;
  description?: string | ReactElement;
  type: 'success' | 'error' | 'warning' | 'info';
  closable?: boolean;
  className?: string;
};

export function Alert({
  message,
  description,
  type,
  closable = false,
  className,
}: AlertProps) {
  const { t } = useTranslation('common');
  const id = generateId();

  return (
    <div
      id={id}
      className={twMerge([style.Container({ type }), className])}
      role="alert"
    >
      <div className={style.Content()}>
        {/* ICON */}
        <div className={style.Icon()}>
          {type === 'success' && (
            <CheckCircle2 className="text-teal-400" size={20} />
          )}
          {type === 'error' && <XCircle className="text-red-400" size={20} />}
          {type === 'warning' && (
            <AlertTriangle className="text-yellow-400" size={20} />
          )}
          {type === 'info' && <Info className="fill-brand-400 w-5 h-5" />}
        </div>

        {/* MESSAGE */}
        <div className={style.MessageContainer()}>
          {message && <div className={style.Message({ type })}>{message}</div>}

          {description && (
            <div className={style.Description({ type })}>{description}</div>
          )}
        </div>

        {/* DISMISS BUTTON */}
        {closable && (
          <div className="pl-3 ml-auto">
            <div className="-mx-1.5 -my-1.5">
              <button
                type="button"
                className={style.DismissButton({ type })}
                data-hs-remove-element={`#${id}`}
              >
                <span className="sr-only">{t('dismiss')}</span>
                <X className={style.ICON_COLORS_BY_TYPE[type]} size={20} />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
